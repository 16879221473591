/* eslint-disable react/no-array-index-key */
import {
  Box,
  Container,
  Divider,
  Grid,
  Typography,
  Button,
  experimentalStyled,
  ButtonGroup,
} from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { homePageLinks } from './MainNavlinks';
import { HomeTestimonials, HomeInspiration } from './home';
import PropTypes from 'prop-types';
// import Logo from './Logo';

const NavButton = experimentalStyled(Button)(() => ({
  border: 0,
  borderRadius: 0,
  whiteSpace: 'nowrap',
  minWidth: 'max-content',
  boxShadow: 'none',
}));

const Footer = (props) => {
  const { pathname } = useLocation();

  return (
    <Box
      color="primary"
      sx={{
        backgroundColor: 'primary.main',
        // backgroundColor: ({ palette }) => palette.primary.main,
        pb: 6,
        // pt: {
        //   md: 15,
        //   xs: 6,
        // },
      }}
      {...props}
    >
      <HomeInspiration applyFilter={pathname !== '/'} />
      <HomeTestimonials />
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid
            item
            sx={{
              display: 'flex',
            }}
            xs={12}
            alignItems="center"
          />
          {/* <ButtonGroup
					sx={{
						lineHeight: 34,
						height: 34,
						margin: '0 auto',
						display: { xs: 'none', sm: 'flex' },
					}}
				>
					{homePageLinks.map((el, i) => (
						<NavButton
							component={RouterLink}
							to={el.to}
							size="small"
							variant="contained"
							key={i}
						>
							{el.label}
						</NavButton>
					))}
				</ButtonGroup> */}
          <ButtonGroup
            sx={{
              margin: '10px auto',
              display: { xs: 'none', sm: 'none', md: 'none', lg: 'flex' },
              alignItems: 'flex-start',
            }}
          >
            {homePageLinks.map((el, i) => {
              if (!el.children) {
                return (
                  <NavButton
                    sx={{
                      minWidth: '130px !important',
                      fontSize: '1rem',
                      '&:hover': {
                        backgroundColor: 'transparent',
                        color: 'black',
                        boxShadow: 'none',
                      },
                    }}
                    color="primary"
                    component={RouterLink}
                    to={el.to}
                    size="small"
                    variant="contained"
                    key={i}
                  >
                    <b>{el.label}</b>
                  </NavButton>
                );
              }
              return <DD item={el} key={i} />;
            })}
            <NavButton
              sx={{
                minWidth: '180px !important',
                fontSize: '1rem',
                '&:hover': {
                  backgroundColor: 'transparent',
                  color: 'black',
                  boxShadow: 'none',
                },
              }}
              color="primary"
              component={RouterLink}
              to="/Resellers"
              size="small"
              variant="contained"
            >
              <b>RESELLERS</b>
            </NavButton>
            <Button
              href="https://www.facebook.com/Party411"
              target="_blank"
              sx={{ px: '0px' }}
              style={{
                borderRadius: 0,
                marginTop: '2px',
                fontSize: '1rem',
              }}
            >
              <i className="fab fa-facebook-f" style={{ color: 'white' }} />
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item xs={12} textAlign="center" alignItems="center" mt={3}>
          <img alt="Footer Logo" src="/static/home/SET-FOOTER-LOGO.png" />

          <Typography color="primary.contrastText">
            SPECIAL EVENT TICKETING
          </Typography>
          <Typography color="primary.contrastText" mt={3} fontSize={30}>
            314-787-8107
          </Typography>
          <Typography color="primary.contrastText">7 Summers Lane</Typography>
          <Typography color="primary.contrastText">
            Hilton Head Island, SC 29926
          </Typography>
        </Grid>
        <Divider
          sx={{
            borderColor: (theme) =>
              alpha(theme.palette.primary.contrastText, 0.12),
            my: 2,
          }}
        />
        <Typography color="primary.contrastText" sx={{ textAlign: 'center' }}>
          &copy; 2022 Special Event Ticketing | Crafted and Hosted by Sabre
          Creative
        </Typography>
      </Container>
    </Box>
  );
};

const DD = ({ item }) => (
  <>
    <Box color="primary" className="footerdropdown">
      <NavButton
        className="footer-dropbtn"
        sx={{ backgroundColor: 'primary.main', height: 36 }}
      >
        <b>{item.label}</b>
      </NavButton>
      <div className="footer-dropdown-content">
        {item.children.map((child, i) => (
          <NavButton
            color="primary"
            component={RouterLink}
            to={child.to}
            key={i}
          >
            {child.label}
          </NavButton>
        ))}
      </div>
    </Box>
  </>
);

DD.propTypes = {
  item: PropTypes.any,
};

export default Footer;
