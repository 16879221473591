/* eslint-disable react/no-array-index-key */
import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Drawer, Link, Divider } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// import Logo from './Logo';
// import useSettings from '../hooks/useSettings';
import { homePageLinks } from './MainNavlinks';
import useAuth from '../hooks/useAuth';
import useCart from '../hooks/useCart';

const MainSidebar = (props) => {
  // const {
  // 	settings: { reseller },
  // } = useSettings();
  // const { logo } = reseller;
  const { onMobileClose, openMobile } = props;
  const pathname = useLocation();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const { isAuthenticated, logout } = useAuth();
  const { itemCount } = useCart();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [pathname]);

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={!lgUp && openMobile}
      variant="temporary"
      PaperProps={{
        sx: {
          backgroundColor: 'background.default',
          width: 256,
        },
      }}
    >
      <Box
        sx={{
          alignItems: 'flex-start',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          p: 2,
        }}
      >
        {/* <RouterLink to="/">
					<Logo src={logo} sx={{ maxWidth: '100%' }} />
				</RouterLink> */}
        {homePageLinks.map((el, i) => {
          if (!el.children) {
            return (
              <Link
                color="primary.main"
                component={RouterLink}
                to={el.to}
                underline="none"
                fontWeight="600"
                variant="body1"
                key={i}
                sx={{ py: '10px' }}
              >
                {el.label}
              </Link>
            );
          }
          return <DD item={el} key={i} />;
        })}

        <Divider sx={{ width: '100%' }} />
        {itemCount > 0 && (
          <>
            <Link
              color="primary.main"
              component={RouterLink}
              underline="none"
              fontWeight="600"
              variant="body1"
              sx={{ py: '10px' }}
              style={{ borderRadius: 0 }}
              to="/Cart"
            >
              CART ({itemCount})
            </Link>
          </>
        )}
        {isAuthenticated && (
          <Link
            color="primary.main"
            underline="none"
            fontWeight="600"
            variant="body1"
            sx={{ py: '10px' }}
            style={{ borderRadius: 0 }}
          >
            ACCOUNT
          </Link>
        )}
        {isAuthenticated && (
          <Link
            color="primary.main"
            underline="none"
            fontWeight="600"
            variant="body1"
            sx={{ py: '10px' }}
            onClick={logout}
            style={{ borderRadius: 0 }}
          >
            LOGOUT
          </Link>
        )}
        {!isAuthenticated && (
          <Link
            color="primary.main"
            underline="none"
            fontWeight="600"
            variant="body1"
            sx={{ py: '10px' }}
            component={RouterLink}
            to="/account"
          >
            CUSTOMER LOGIN
          </Link>
        )}
        <Link
          color="primary.main"
          underline="none"
          fontWeight="600"
          variant="body1"
          sx={{ py: '10px' }}
          component={RouterLink}
          to="/Resellers"
        >
          RESELLERS
        </Link>
        <a
          href="https://www.facebook.com/Party411"
          target="_blank"
          style={{
            borderRadius: 0,
            marginTop: '2px',
            fontSize: '1rem',
          }}
          rel="noreferrer"
        >
          <i className="fab fa-facebook-f" style={{ color: '#6d8bc7' }} />
        </a>
        {/* <Box
					sx={{
						display: 'flex',
						pb: 2,
						pt: 3,
					}}
				>
					<Link
						color="textSecondary"
						component={RouterLink}
						to="/browse"
						underline="none"
						variant="body1"
					>
						Browse Components
					</Link>
					<Chip
						color="primary"
						label="NEW"
						size="small"
						sx={{
							maxHeight: 20,
							ml: 1,
							mr: 2,
						}}
					/>
				</Box>
				<Link
					color="textSecondary"
					component={RouterLink}
					to="/docs"
					underline="none"
					variant="body1"
				>
					Documentation
				</Link>
				<Button
					color="primary"
					component="a"
					href="https://material-ui.com/store/items/devias-kit-pro"
					size="small"
					sx={{ mt: 4 }}
					target="_blank"
					variant="contained"
				>
					Get the kit
				</Button> */}
      </Box>
    </Drawer>
  );
};

const DD = ({ item }) => (
  <>
    {item.children.map((child, i) => (
      <Link
        color="primary.main"
        component={RouterLink}
        to={child.to}
        underline="none"
        fontWeight="600"
        variant="body1"
        key={i}
        sx={{ py: '10px', textTransform: 'uppercase' }}
      >
        {child.label}
      </Link>
    ))}
  </>
);

DD.propTypes = {
  item: PropTypes.any,
};

MainSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default MainSidebar;
